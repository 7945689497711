.navigation {
    display: flex;
    width: 100%;
    font-size: 14px;
    gap: 12px;
    justify-content: flex-end;
}

.navigation__item {
    padding: 8px 16px;
    -webkit-border-radius: 19px;
    -moz-border-radius: 19px;
    border-radius: 19px;
    text-decoration: none;
}

.navigation__item--normal {
    background-color: #FFF;
    color: #000;
}

.navigation__item--normal:hover {
    background-color: rgba(0,0,0,0.2);
    color: #000;
    cursor: pointer;
}

.navigation__item--selected {
    background-color: #000;
    color: #FFF;
}

.navigation__item--selected:hover {
    background-color: #000;
    color: #FFF;
}


@media screen and (max-width: 575px) {
    .navigation {
        display: none;
    }
}