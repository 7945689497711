.head {
    padding: 16px 40px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #D9D9D9;
}

.head__logotype {
    display:  flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}

.head__logotype img {
    display: block;
}

@media screen and (max-width: 575px) {
    .head__logotype {
        padding: 9px 0px;
        justify-content: center;
    }
}