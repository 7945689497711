/* ГЛАВНАЯ СЕКЦИЯ */

.banner {
  padding: 100px 0px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
}

.offer {
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.offer__icon {
  display: block;
  margin-bottom: 60px;
  max-height: 144px;
}

.offer__title {
  font-size: 64px;
  line-height: 66px;
  font-weight: 600;
}

.offer__description {
  margin-top: 48px;
  font-size: 21px;
  line-height: 30px;
  color: #aaabad;
  margin-bottom: 0px;
}

.offer__buttons {
  margin-top: 32px;
}

.offer__button {
  margin-right: 16px;
}

.banner__iphone {
  display: block;
  margin: 0 auto;
  max-height: 675px;
}

/* СЕКЦИЯ С ОТЗЫВАМИ */

.reviews {
  padding: 100px 0px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
}

.review {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  padding: 24px;
  background-color: #e6f1fc;
}

.review__stars {
  font-display: flex;
  gap: 4px;
}

.review__stars svg {
  margin-right: 4px;
}

.review__title {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.review__text {
  font-size: 16px;
  line-height: 24px;
  color: #3d4f67;
  margin-top: 12px;
}

.review__organization {
  color: #007aff;
  font-size: 18px;
  line-height: 18px;
  margin-top: 12px;
}

/* СЕКЦИИ С ФИЧАМИ */

.feature1 {
  padding: 100px 0px 50px 0px;
  background-color: #fff;
}

.feature2 {
  padding: 50px 0px 50px 0px;
  background-color: #fff;
}

.feature3 {
  padding: 50px 0px 100px 0px;
  background-color: #fff;
}

.feature {
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.feature__title {
  font-size: 28px;
  line-height: 66px;
  font-weight: 600;
  max-width: 450px;
  line-height: 33px;
}

.feature__description {
  margin-top: 44px;
  font-size: 21px;
  line-height: 30px;
  color: #aaabad;
  margin-bottom: 0px;
}

.feature__image {
  display: block;
  margin: 0 auto;
  max-height: 675px;
}

.feature__link {
  display: block;
  color: #007aff;
  text-decoration: none;
  line-height: 19px;
  font-size: 19px;
  margin-top: 32px;
}

/* СЕКЦИЯ С КОНТАКТАМИ */

.contact {
  background-color: #f6f7f8;
  padding: 100px 0px;
}

/* ФУТЕР */

.footer {
  background-color: #000;
}

.footer__label {
  color: #fff;
  line-height: 24px;
  font-size: 14px;
  padding: 24px 0px;
  text-align: center;
}

.footer__label a {
  color: #fff;
  text-decoration: none;
}

.footer__label a:hover {
  color: #fff;
  cursor: pointer;
}

.details {
  display: flex;
  flex-wrap: wrap;
}

.details__item {
  width: 50%;
  margin-bottom: 48px;
}

.details__item h4 {
  font-size: 28px;
  color: #000;
  font-weight: 600;
  line-height: 33px;
}

.details__item span {
  font-size: 17px;
  color: #3d4f67;
  line-height: 33px;
  margin-top: 16px;
}

/* ШАПКА СЕКЦИИ */

.sectionHead {
  margin-bottom: 54px;
}

.sectionHead__title {
  font-size: 48px;
  line-height: 52px;
  text-align: center;
}

.sectionHead__description {
  margin-top: 16px;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  color: #aaabad;
  margin-bottom: 0px;
}

/* АДАПТИВ */

@media screen and (max-width: 1279px) {
  .banner {
    padding: 100px 0px;
    border-bottom: 1px solid #d9d9d9;
    background-color: #fff;
  }

  .offer {
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }

  .offer__icon {
    display: block;
    margin-bottom: 50px; /* CHANGED */
    max-height: 120px; /* CHANGED */
  }

  .offer__title {
    font-size: 54px; /* CHANGED */
    line-height: 56px; /* CHANGED */
    font-weight: 600;
  }

  .offer__description {
    margin-top: 36px; /* CHANGED */
    font-size: 20px; /* CHANGED */
    line-height: 28px; /* CHANGED */
    color: #aaabad;
    margin-bottom: 0px;
  }

  .offer__buttons {
    margin-top: 32px;
  }

  .offer__button {
    margin-right: 16px;
  }

  .banner__iphone {
    display: block;
    margin: 0 auto;
    max-height: 675px;
  }
}

@media screen and (max-width: 767px) {
  .banner {
    padding: 100px 0px;
    border-bottom: 1px solid #d9d9d9;
    background-color: #fff;
  }

  .offer {
    height: 100%;
    display: flex;
    align-items: center; /* CHANGED */
    flex-direction: column;
    justify-content: center;
  }

  .offer__icon {
    display: block;
    margin-bottom: 50px;
    max-height: 144px; /* CHANGED */
  }

  .offer__title {
    font-size: 50px; /* CHANGED */
    line-height: 56px;
    font-weight: 600;
    text-align: center; /* CHANGED */
  }

  .offer__description {
    margin-top: 32px;
    font-size: 20px;
    line-height: 28px;
    color: #aaabad;
    text-align: center; /* CHANGED */
    margin-bottom: 0px;
  }

  .offer__buttons {
    margin: 32px 0px;
    display: flex; /* CHANGED */
    gap: 16px; /* CHANGED */
    flex-direction: column;
    width: 100%;
  }

  .offer__button {
    margin: 0px; /* CHANGED */
    display: block; /* CHANGED */
  }

  .banner__iphone {
    display: block;
    margin: 0 auto;
    max-height: 675px;
  }

  .reviews {
    padding: 50px 0px;
    border-bottom: 1px solid #d9d9d9;
    background-color: #fff;
  }

  .review {
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    padding: 24px;
    background-color: #e6f1fc;
  }

  .review--first {
    margin-bottom: 24px; /* CHANGED */
  }

  .review--second {
    margin-bottom: 24px; /* CHANGED */
  }

  .review__stars {
    font-display: flex;
    gap: 4px;
  }

  .review__stars svg {
    margin-right: 4px;
  }

  .review__title {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    margin-top: 20px;
  }

  .review__text {
    font-size: 16px;
    line-height: 24px;
    color: #3d4f67;
    margin-top: 12px;
  }

  .review__organization {
    color: #007aff;
    font-size: 18px;
    line-height: 18px;
    margin-top: 12px;
  }

  .sectionHead {
    margin-bottom: 48px; /* CHANGED */
  }

  .sectionHead__title {
    font-size: 40px; /* CHANGED */
    line-height: 48px; /* CHANGED */
    text-align: center;
  }

  .sectionHead__description {
    margin-top: 16px;
    font-size: 15px; /* CHANGED */
    line-height: 24px; /* CHANGED */
    text-align: center;
    color: #aaabad;
    margin-bottom: 0px;
  }

  .feature1 {
    padding: 50px 0px; /* CHANGED */
    border-bottom: 1px solid #d9d9d9; /* CHANGED */
    background-color: #fff;
  }

  .feature2 {
    padding: 50px 0px; /* CHANGED */
    border-bottom: 1px solid #d9d9d9; /* CHANGED */
    background-color: #fff;
  }

  .feature3 {
    padding: 50px 0px; /* CHANGED */
    background-color: #fff;
  }

  .feature {
    height: 100%;
    display: flex;
    align-items: center; /* CHANGED */
    flex-direction: column;
    justify-content: center;
  }

  .feature__title {
    font-size: 28px;
    line-height: 66px;
    font-weight: 600;
    max-width: none; /* CHANGED */
    width: 100%; /* CHANGED */
    text-align: center; /* CHANGED */
    line-height: 33px;
  }

  .feature__description {
    margin-top: 44px;
    font-size: 21px;
    line-height: 30px;
    color: #aaabad;
    text-align: center; /* CHANGED */
  }

  .feature__image {
    display: block;
    margin: 0 auto;
    max-height: 675px;
  }

  .feature__link {
    display: block;
    color: #007aff;
    text-decoration: none;
    line-height: 19px;
    font-size: 19px;
    margin-top: 32px;
    margin-bottom: 32px; /* CHANGED */
  }

  .contact {
    padding: 50px 0px;
  }
}

@media screen and (max-width: 575px) {
  .banner {
    padding: 50px 0px; /* CHANGED */
  }

  .offer__buttons {
    margin: 32px 0px 48px 0px; /* CHANGED */
    display: flex; /* CHANGED */
    gap: 16px; /* CHANGED */
  }
}

@media screen and (max-width: 414px) {
  .sectionHead {
    margin-bottom: 48px;
  }

  .sectionHead__title {
    font-size: 36px; /* CHANGED */
    line-height: 48px;
    text-align: center;
  }

  .sectionHead__description {
    margin-top: 16px;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #aaabad;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 393px) {
  .offer__title {
    font-size: 48px; /* CHANGED */
  }
}

@media screen and (max-width: 390px) {
}

@media screen and (max-width: 375px) {
  .offer__title {
    font-size: 44px; /* CHANGED */
    line-height: 48px; /* CHANGED */
  }

  .offer__description {
    margin-top: 32px;
    font-size: 18px; /* CHANGED */
    line-height: 24px; /* CHANGED */
    color: #aaabad;
    text-align: center;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 360px) {
}
